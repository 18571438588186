import React from 'react'
import cn from 'classnames'
import { Table, Tooltip } from 'antd';
import { UnlockOutlined, LeftOutlined } from '@ant-design/icons';
import { IColumn } from 'interfaces'
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { useTranslation } from 'react-i18next';
import { getDocumentMobileColumns } from '../../components/DocumentTable/columns/documentMobileColumns'
import { fetchAvailableDocuments } from 'store/reducers/documentsCreator';
import { availableIconFormat, documnentIcons } from '../../helpers/documentIcons';
import { setIsOpenViewer } from 'store/slices/viewerSlice';
import fetchDocumentToViewer from 'store/reducers/viewerCreator';
import { setCurrentFolder, setSelectedDocument } from 'store/slices/dataDocumentsSlice';
import { fetchUserData } from 'store/reducers/userDataCreator';
import { setMoreInfoHidden } from 'store/slices/windowStateSlice';
import { setSelectedKeys } from 'store/slices/windowStateSlice';
import Spinner from 'components/Spinner/Spinner';
import WebViewer from 'components/Webviewer/WebViewer';
import TableLoader from 'components/Sceletons/TableLoader';

import classes from './MobileRoom.module.scss'

const MobileRoom = () => {
    const [modifiedColumns, setModifiedColumns] = React.useState<IColumn[]>([]);
    const [delayClickTimer] = React.useState<NodeJS.Timeout>();
    const { isOpenViewer, documentUrl, isDocumentLoading, isDocumentError } = useAppSelector(state => state.viewer);
    const { dataDocumentTable, isLoadingDocuments, currentFolder } = useAppSelector(store => store.documents);
    const { userAndRoomStatus } = useAppSelector((store) => store.userData);
    const { isLoadingProcess } = useAppSelector((store) => store.windowState);
    const { dataRoom } = useAppSelector((state) => state.dataRoom);
    const params = useParams();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();

    const currentFolderId = params.folderId || null;

    const privateRooms = ['85141b41-bc64-4623-b293-2ba4bbea0e6f', '143d9fd4-96b7-46e8-819f-9515a04eaff9']

    React.useEffect(() => {
        const pendingUser = async () => {
            await dispatch(fetchUserData({}));
        }
        pendingUser()
    }, [userAndRoomStatus])
    
    React.useEffect(() => {
        if (params.room) {
            if (currentFolderId) {
                dispatch(fetchAvailableDocuments({ roomId: params.room, folderId: currentFolderId }));
            } else {
                dispatch(fetchAvailableDocuments({ roomId: params.room, folderId: null }));
            }
        }
    }, [params.room, currentFolderId])

    React.useEffect(() => {
        const columns = getDocumentMobileColumns(t)
        const customColumns = columns?.map((col: IColumn) => ({
            ...col,
            render: (value: any, info: any) => renderCell({value, info, col})
        }))
        setModifiedColumns(customColumns);
    }, [dataDocumentTable])

    const onWebViewClose = () => {
        navigate(-1);
        dispatch(setIsOpenViewer(false))
    };

    const onBack = () => {
        const navStep: any = !currentFolderId ? '/rooms' : -1
        dispatch(setSelectedKeys(['rooms']));
        localStorage.removeItem('previousRoomId');
        localStorage.removeItem('previousFolderId');
        navigate(navStep)
    }

    const renderCell = (props: any) => {
        const { value, info, col } = props;
        const icon = availableIconFormat.includes(info.type) ? info.type : 'default';
        const isConfidential = info?.is_confidential;
            
        if(col.key === 'name'){
            if (info.status === 0) {
              return (
                <div className={classes.rowWrap} style={{ justifyContent: 'space-between' }}>
                  <div className={classes.icon} style={{display: 'flex'}}>
                    {documnentIcons[icon as keyof typeof documnentIcons]} 
                  </div>
                  {value}
                  <Spinner />
                </div>
              );
            } else {
              return <div className={classes.rowWrap}>
                <div className={classes.icon} style={{display: 'flex'}}>
                  {documnentIcons[icon as keyof typeof documnentIcons]}
                </div>
                { isConfidential && <div className={cn(classes.conditentialIcon, 'confidential')}>
                  <Tooltip title={t('Documents.table.confidential')}> <UnlockOutlined style={{color: 'red'}}/> </Tooltip>
                </div>}
                {value}
              </div>;
            };
          }
       
        return value;
    }

    if (isLoadingDocuments) {   
        return (
          <TableLoader height={'100vh'}/>
        )
    }

    return (
        <div className={classes.mobileTable}>
            {privateRooms.includes(params.room!) && <div className={classes.logo} />}
            <div style={{ top: privateRooms.includes(params.room!) ? '104px' : '16px' }} className={classes.backBtn} onClick={onBack}>
                <LeftOutlined />
            </div>
            <Table
                locale={{emptyText:
                    <div style={{height:'calc(100vh - 375px)', display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: 18}}>
                    {t(`Documents.table.fallback`)}
                    </div>
                }}
                className={classes.table}
                dataSource={dataDocumentTable!}
                columns={modifiedColumns}
                pagination={false}
                size='large'
                expandable={{
                    expandIcon: () => null
                }}
                rowClassName={(record) => {
                    return isLoadingProcess ||
                    record.status === 0
                    ? classes.tableRowLoading
                    : classes.tableRow;
                }}
                onRow={(record, idx: any) => {
                    const onTap = async (event: any) => {
                        if(record.status === 0) return;
                        if (record.type === 'youtube') {
                            // @ts-ignore
                            window.open(record.description, '_blank')
                            event.stopPropagation();
                            clearTimeout(delayClickTimer);
                            return
                        }
                        if (record.type === 'folder') {
                            clearTimeout(delayClickTimer);
                            dispatch(setSelectedDocument(null));
                            dispatch(setMoreInfoHidden(true));
                            dispatch(setCurrentFolder([...currentFolder, record]));
                            navigate(`/room/${params.room}/mobile/${record.id}`)
                            localStorage.setItem('previousFolderPath', JSON.stringify([...currentFolder, record]))
                            return;
                        }
                        event.stopPropagation();
                        clearTimeout(delayClickTimer);
            
                        navigate(`${window.location.pathname}?webviewer_file_id=${record.id}`);
                        dispatch(setIsOpenViewer(true));
                        await dispatch(fetchDocumentToViewer({
                            token: record.token,
                            extension: record.extension,
                            is_agreement: false,
                            id: dataRoom?.id,
                            // @ts-ignore
                            entity: record.id,
                            action: record.is_confidential ? 'view_confident': 'view',
                            locale: i18n.language
                        }));
                    };
                    
                    return {
                        onClick: onTap
                    }
                }}
            />
            {isOpenViewer && (
                <React.Suspense fallback={<div />}>
                <WebViewer
                    errorMessage={isDocumentError}
                    onClose={onWebViewClose}
                    isLoading={isDocumentLoading}
                    url={documentUrl}
                    isMobile
                />
                </React.Suspense>
            )}
        </div>
    )
}

export default MobileRoom
