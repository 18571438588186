import { FileFilled, LockFilled, SettingFilled } from '@ant-design/icons';
import { faBriefcase, faRectangleList } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Segmented, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import classes from './HeaderComponent.module.scss';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { setSelectedKeys } from 'store/slices/windowStateSlice';
import { useNavigate } from 'react-router-dom';
import { useMemo } from 'react';
import RowLoader from 'components/Sceletons/RowLoader';

function Navigation() {
  const { selectedKeys } = useAppSelector((state) => state.windowState);
  const { dataRoom, isLoadingRooms } = useAppSelector((state) => state.dataRoom);
  const { userData, userPermissions, isLoadingUserData } = useAppSelector((store) => store.userData);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const values = useMemo(() => {
    return dataRoom?.id
      ? [
          {
            label: (
              <Tooltip title={t('sidebar.documents')}>
                <FileFilled className={classes.iconMenu} />
              </Tooltip>
            ),
            value: `documents`,
          },
          // {
          //   label: (
          //     <Tooltip title={t('sidebar.sides')}>
          //       <FontAwesomeIcon className={classes.iconMenu} icon={faBriefcase} />
          //     </Tooltip>
          //   ),
          //   value: `sides`,
          // },
        ]
      : [];
  }, [dataRoom, i18n.language]);

  userPermissions?.can_edit_room_settings && values.push({
    label: (
      <Tooltip title={t('sidebar.settings')}>
        <SettingFilled className={classes.iconMenu} />
      </Tooltip>
    ),
    value: `settings`,
  });

  userPermissions?.can_view_logs && values.push({
    label: (
      <Tooltip title={t('sidebar.logs')}>
        <FontAwesomeIcon className={classes.iconMenu} icon={faRectangleList} />
      </Tooltip>
    ),
    value: `logs`,
  });
  
  dataRoom?.agreement_enable && values.push({
    label: (
      <Tooltip title={t('sidebar.nda')}>
        <LockFilled className={classes.iconMenu} />
      </Tooltip>
    ),
    value: `nda`,
  });

  const onSegmentCahnge = (value: string | number) => {
    const selectedValue = value as string;
    dispatch(setSelectedKeys([selectedValue]));
    localStorage.setItem('previousPage', selectedValue);

    if (selectedValue === 'documents') {
      if (dataRoom?.id) navigate(`/room/${dataRoom.custom_url || dataRoom?.id}/documents`);
      else if (userData?.available_rooms[0]?.id)
        navigate(`/room/${userData?.available_rooms[0]?.custom_url || userData?.available_rooms[0]?.id}/documents`);
      else navigate(`/noAvailablesRooms`);
    } else {
      if (dataRoom?.id) navigate(`/room/${dataRoom.custom_url || dataRoom?.id}/${selectedValue}`);
      else if (userData?.available_rooms[0]?.id)
        navigate(`/room/${userData?.available_rooms[0]?.custom_url || userData?.available_rooms[0]?.id}/${selectedValue}`);
    }
  };

  const isPrivateRoom = dataRoom?.id === '143d9fd4-96b7-46e8-819f-9515a04eaff9' // не трогать

  return (
    <div className={classes.navigation}>
      <div className={classes.navigation_title}>
        <Tooltip title={dataRoom?.name} placement='bottomLeft' overlayStyle={{maxWidth: 'calc(100vw - 400px)'}}>
          {dataRoom?.name}  
        </Tooltip>
      </div>
      {isPrivateRoom && <a rel='noreferrer' target='_blank' href='https://usquestlaw.com' className={classes.navigation_logo} />}
      <div className={classes.navigation_icons}>
        {(isLoadingRooms || isLoadingUserData)
          ? <RowLoader width='200px' padding='2px 0 0 0' height='27px' />
          : <Segmented className={classes.navigation_segment} options={values} selected value={selectedKeys[0]} onChange={onSegmentCahnge} />
        }
      </div>
    </div>
  );
}

export default Navigation;
