import React from 'react';
import { Affix, ConfigProvider, Layout, Tabs, TabsProps, Badge } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { setIsResizing, setSelectedKeys, setSideBarWidth } from 'store/slices/windowStateSlice';
import { useTranslation } from 'react-i18next';
import { setDataRoom } from 'store/slices/dataRoomSlice';
import { resetUserPermissions, setSelectedDataRoom } from 'store/slices/userDataSlice';
import { setCurrentFolder, setDocuments } from 'store/slices/dataDocumentsSlice';
import { setGroupChoosen, setGroups, setUserAndData } from 'store/slices/dataUsersSlice';
import { setDataForPermissionTable, setUserPermissions } from 'store/slices/dataPermissionsSlice';
import { resetOrgsAndSides } from 'store/slices/dataSidesSlice';
import { clearAllChatData } from 'store/slices/chatsSlice';
import RoomsTab from './RoomsTab/RoomsTab';
import UsersTab from './UsersTab/UsersTab';
import ChatTab from './ChatTab/ChatTab';
import classes from './SideBar.module.scss';
import colors from '../../scss/variables/colors.module.scss';

const { Sider } = Layout;

export default function SideBar() {
  const sidebarRef = React.useRef<HTMLDivElement>(null);
  const [selectedTab, setSelectedTab] = React.useState('rooms')
  const { selectedKeys, sideBarWidth, isResizing } = useAppSelector((store) => store.windowState);
  const { userData, isLoadingUserData } = useAppSelector((store) => store.userData);
  const { dataRoom, isLoadingRooms } = useAppSelector((store) => store.dataRoom);
  const { roomAllChats } =  useAppSelector((state) => state.chat);

  const isMobile = /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(navigator.userAgent)
  const isLoadingSomeData = isLoadingUserData || isLoadingRooms;

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const startResizing = React.useCallback((mouseDownEvent: any) => {
    dispatch(setIsResizing(true));
  }, []);

  const stopResizing = React.useCallback(() => {
    dispatch(setIsResizing(false));
  }, []);

  const resize = React.useCallback(
    (mouseMoveEvent: any) => {
      if (isResizing) {
        const widthOfSidebar = mouseMoveEvent.clientX - sidebarRef.current!.getBoundingClientRect().left;        
        if (300 <= widthOfSidebar && widthOfSidebar <= 650) {
          dispatch(setSideBarWidth(widthOfSidebar));
          localStorage.setItem('sideBarWidth', String(widthOfSidebar))
        }
      }
    },
    [isResizing]
  );

  React.useEffect(() => {
    window.addEventListener('mousemove', resize);
    window.addEventListener('mouseup', stopResizing);
    return () => {
      window.removeEventListener('mousemove', resize);
      window.removeEventListener('mouseup', stopResizing);
    };
  }, [resize, stopResizing]);

  React.useEffect(() => {
    const defaultTabs = items?.reduce(
      (allTabs: string[], tab: any) => [String(tab.key), ...allTabs],
      [],
    );
    
    const prevTab = localStorage.getItem('prevTab');

    if (prevTab === 'users') {
      setSelectedTab(prevTab)
    } else if (defaultTabs?.includes(selectedKeys[0])) {
      setSelectedTab(selectedKeys[0])
    }
  }, [selectedKeys]);

  const clearRedux = () => {
    dispatch(setDataRoom(null));
    dispatch(clearAllChatData());
    dispatch(setDocuments({ documents: [] }));
    dispatch(resetUserPermissions());
    dispatch(setUserAndData({ users: [], dataUserTable: [] }));
    dispatch(setGroups([]));
    dispatch(setGroupChoosen(null));
    dispatch(setCurrentFolder([]));
    dispatch(setDataForPermissionTable({ documents: [] }));
    dispatch(setUserPermissions(null));
    dispatch(resetOrgsAndSides());
    dispatch(setSelectedDataRoom(undefined));
    localStorage.removeItem('previousRoomId');
    localStorage.removeItem('previousFolderPath');
    localStorage.removeItem('prevTab');
  };

  const onChangeTab = (key: string) => {
    localStorage.setItem('prevTab', key);
    if (key === 'users') {
      setSelectedTab(key);
    } else {
      dispatch(setSelectedKeys([key]))
    }
    
    key === 'chat' && localStorage.setItem('previousPage', key);

    if (key === 'rooms') {      
      if (dataRoom?.id) navigate(`/room/${dataRoom.custom_url || dataRoom?.id}/documents`);
      else navigate(`/${key}`)
    } else if ( key === 'chat') {
      if (dataRoom?.id) navigate(`/room/${dataRoom.custom_url || dataRoom?.id}/${key}`);
      else if (userData?.available_rooms[0]?.id)
        navigate(`/room/${userData?.available_rooms[0]?.custom_url || userData?.available_rooms[0]?.id}/${key}`);
    }
  };

  const badgeLength = roomAllChats.filter((chat) => chat.unviewed_amount > 0).length;

  const privatedRooms = ['143d9fd4-96b7-46e8-819f-9515a04eaff9'] // не трогать
  const isAdmin = userData?.available_rooms.filter(room => privatedRooms.includes(room.id))[0]?.permissions?.can_edit_roles // и это тоже

  const items: TabsProps['items'] = [
    {
      key: 'rooms',
      label: t('sidebar.shortRooms'),
      children: <RoomsTab clearRedux={clearRedux}/>,
    },
    {
      key: 'users',
      label: t('sidebar.users'),
      children: <UsersTab />,
      disabled: !dataRoom || isLoadingSomeData || (privatedRooms.includes(dataRoom?.id) && !isAdmin),
    },
    {
      key: 'chat',
      label: (
        <Badge
          offset={[badgeLength > 99 ? 15 : 10, -2]}
          style={{ fontSize: '10px' }}
          size='small'
          count={badgeLength}
          overflowCount={99}
        >
          <span style={{color: !dataRoom || (privatedRooms.includes(dataRoom?.id) && !isAdmin) ? 'rgba(0, 0, 0, 0.25)' : 'inherit'}}>{t('sidebar.chats')}</span>
        </Badge>
      ),
      children: <ChatTab />,
      disabled: !dataRoom || (privatedRooms.includes(dataRoom?.id) && !isAdmin),
    },
  ];

  
  const defaultTabs = items?.reduce(
    (allTabs: string[], tab: any) => [String(tab.key), ...allTabs],
    [],
  );

  const itemSelectedColor = selectedTab === 'users' || [...defaultTabs, 'documents'].includes(selectedKeys[0]) 
    ? colors.primary100
    : colors.borderColor

  React.useEffect(() => {
    
    const prevTab = localStorage.getItem('prevTab');

    if (prevTab === 'users') {
      setSelectedTab(prevTab)
    } else if (defaultTabs?.includes(selectedKeys[0])) {
      setSelectedTab(selectedKeys[0])
    }
  }, [selectedKeys]);

  return (
    <Affix>
      <Sider
        className={classes.sider}
        width={isMobile ? '100vw' : sideBarWidth}
        ref={sidebarRef}
      >
        <div className={classes.tabsWrap}>
          <ConfigProvider theme={{ components: { Tabs: { itemSelectedColor } }}}>
            <Tabs
              className={classes.tabsStyles}
              activeKey={selectedTab}
              defaultActiveKey={'documents'}
              size='middle'
              items={items}
              onChange={onChangeTab}
            />
          </ConfigProvider>
          <div
            className={classes.resizer}
            onMouseDown={startResizing} 
          />
        </div>
      </Sider>
    </Affix>
  );
}
