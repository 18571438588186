import * as pdfjsLib from 'pdfjs-dist';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { VariableSizeList } from 'react-window';
import { useAppDispatch } from 'store/hook';
import { setIsDocumentError } from 'store/slices/viewerSlice';
import useResizeObserver from 'use-resize-observer';
import Toolbar from '../Toolbar';
import classes from '../Viewer.module.scss';
import Viewer from './Viewer';

interface PDFViewerProps {
  url?: any;
  data64?: string;
  isModal?: boolean;
  setIsAvailableAccept?: React.Dispatch<React.SetStateAction<boolean>>;
  onClose?: () => void;
  downloadDocument?: () => void;
  isMobile?: boolean;
}

const PDFViewer: React.FC<PDFViewerProps> = ({
  url,
  data64,
  onClose,
  isModal,
  setIsAvailableAccept,
  downloadDocument,
  isMobile = false,
}) => {
  const defaultZoom = isMobile ? 0.8 : 1.5;
  const [pagesCount, setPagesCount] = React.useState(0);
  const [scale, setScale] = React.useState<number>(defaultZoom);
  const [currentPage, setCurrentPage] = React.useState(1);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const pdfRef = React.useRef<pdfjsLib.PDFDocumentProxy | null>(null);
  const windowRef = React.useRef<VariableSizeList | null>(null);

  const { ref: toolbarRef, height = 0 } = useResizeObserver();
  const { ref, width: internalWidth = 400, height: internalHeight = 600 } = useResizeObserver();

  React.useEffect(() => {
    renderPage();
  }, []);

  const renderPage = async () => {
    try {
      const data = atob(data64 || '');

      const pdf = url ? await pdfjsLib.getDocument(url).promise : await pdfjsLib.getDocument({ data }).promise;

      pdfRef.current = pdf;
      setPagesCount(pdf?._pdfInfo.numPages);

      // Fetch the first page
      const pageNumber = 1;
      await pdf?.getPage(pageNumber);
    } catch (err) {
      dispatch(setIsDocumentError(t('Documents.error.unSupportedView')))
    }
  };

  const handleGetPdfPage = React.useCallback((index: any) => {
    return pdfRef.current!.getPage(index + 1);
  }, []);

  return (
    <div ref={ref} className={classes.page_overlay}>
      <Toolbar
        scale={scale}
        toolbarRef={toolbarRef}
        setScale={setScale}
        windowRef={windowRef}
        pagesCount={pagesCount}
        onClose={onClose}
        isModal={isModal}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        downloadDocument={downloadDocument}
        isMobile={isMobile}
      />
      <Viewer
        pagesCount={pagesCount}
        scale={scale}
        handleGetPdfPage={handleGetPdfPage}
        windowRef={windowRef}
        height={internalHeight - height}
        width={internalWidth}
        setIsAvailableAccept={setIsAvailableAccept}
        setCurrentPage={setCurrentPage}
      />
    </div>
  );
};

export default PDFViewer;
